/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** icon for logout used mainly in menu */
const RouteLineIcon = ({ color }) => {
  return (
    <svg
      width="54"
      height="1"
      viewBox="0 0 54 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        max-width: 100%;
        height: 1px;
        width: 52px;
        vertical-align: middle;
      `}
    >
      <path
        d="M53 0.5L1.41175 0.5"
        stroke-linecap="square"
        stroke-dasharray="2 5"
        stroke={color}
      />
    </svg>
  );
};

export default RouteLineIcon;

RouteLineIcon.propTypes = {
  /** color for svg */
  color: PropTypes.string,
};
