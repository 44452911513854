/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** icon for logout used mainly in menu */
const StartPointIcon = ({ color }) => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        max-width: 100%;
        width: 8px;
        height: 8px;
        vertical-align: middle;
      `}
    >
      <path
        d="M4.5 8C6.433 8 8 6.433 8 4.5C8 2.567 6.433 1 4.5 1C2.567 1 1 2.567 1 4.5C1 6.433 2.567 8 4.5 8Z"
        stroke={color}
      />
    </svg>
  );
};

export default StartPointIcon;

StartPointIcon.propTypes = {
  /** color for svg */
  color: PropTypes.string,
};
