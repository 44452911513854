/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** icon for logout used mainly in menu */
const UserIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      css={css`
        fill: ${color || '#002d59'};
        max-width: 100%;
        height: 18px;
        width: 18px;
        vertical-align: middle;
        @media screen and (max-width: 1025px) {
          margin-right: 10px;
        }
      `}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.63812 11.4685C7.7825 10.5848 6.5 8.69216 6.5 6.5C6.5 3.46243 8.96243 1 12 1C15.0376 1 17.5 3.46243 17.5 6.5C17.5 8.69216 16.2175 10.5848 14.3619 11.4685C19.3019 12.5493 23 16.9499 23 22.2143H1C1 16.9499 4.69806 12.5493 9.63812 11.4685ZM12 11.2143C14.6036 11.2143 16.7143 9.10363 16.7143 6.5C16.7143 3.89637 14.6036 1.78571 12 1.78571C9.39637 1.78571 7.28571 3.89637 7.28571 6.5C7.28571 9.10363 9.39637 11.2143 12 11.2143ZM1.81548 21.4286C2.21669 16.1545 6.62318 12 12 12C17.3768 12 21.7833 16.1545 22.1845 21.4286H1.81548Z"
        fill={color}
      />
      <path
        d="M9.63812 11.4685L9.67018 11.615L10.0981 11.5214L9.70261 11.333L9.63812 11.4685ZM14.3619 11.4685L14.2974 11.333L13.9019 11.5214L14.3298 11.615L14.3619 11.4685ZM23 22.2143V22.3643H23.15V22.2143H23ZM1 22.2143H0.85V22.3643H1V22.2143ZM1.81548 21.4286L1.66592 21.4172L1.65364 21.5786H1.81548V21.4286ZM22.1845 21.4286V21.5786H22.3464L22.3341 21.4172L22.1845 21.4286ZM6.35 6.5C6.35 8.75217 7.66775 10.6963 9.57362 11.6039L9.70261 11.333C7.89725 10.4733 6.65 8.63215 6.65 6.5H6.35ZM12 0.85C8.87959 0.85 6.35 3.37959 6.35 6.5H6.65C6.65 3.54528 9.04528 1.15 12 1.15V0.85ZM17.65 6.5C17.65 3.37959 15.1204 0.85 12 0.85V1.15C14.9547 1.15 17.35 3.54528 17.35 6.5H17.65ZM14.4264 11.6039C16.3323 10.6963 17.65 8.75217 17.65 6.5H17.35C17.35 8.63215 16.1027 10.4733 14.2974 11.333L14.4264 11.6039ZM23.15 22.2143C23.15 16.878 19.4014 12.4175 14.3939 11.3219L14.3298 11.615C19.2025 12.6811 22.85 17.0219 22.85 22.2143H23.15ZM1 22.3643H23V22.0643H1V22.3643ZM9.60606 11.3219C4.5986 12.4175 0.85 16.878 0.85 22.2143H1.15C1.15 17.0219 4.79752 12.6811 9.67018 11.615L9.60606 11.3219ZM16.5643 6.5C16.5643 9.02079 14.5208 11.0643 12 11.0643V11.3643C14.6865 11.3643 16.8643 9.18647 16.8643 6.5H16.5643ZM12 1.93571C14.5208 1.93571 16.5643 3.97921 16.5643 6.5H16.8643C16.8643 3.81353 14.6865 1.63571 12 1.63571V1.93571ZM7.43571 6.5C7.43571 3.97921 9.47921 1.93571 12 1.93571V1.63571C9.31353 1.63571 7.13571 3.81353 7.13571 6.5H7.43571ZM12 11.0643C9.47921 11.0643 7.43571 9.02079 7.43571 6.5H7.13571C7.13571 9.18647 9.31353 11.3643 12 11.3643V11.0643ZM12 11.85C6.54415 11.85 2.07303 16.0655 1.66592 21.4172L1.96505 21.4399C2.36035 16.2435 6.70221 12.15 12 12.15V11.85ZM22.3341 21.4172C21.927 16.0655 17.4559 11.85 12 11.85V12.15C17.2978 12.15 21.6396 16.2435 22.0349 21.4399L22.3341 21.4172ZM1.81548 21.5786H22.1845V21.2786H1.81548V21.5786Z"
        fill={color}
      />
    </svg>
  );
};

export default UserIcon;

UserIcon.propTypes = {
  /** color for svg */
  color: PropTypes.string,
};
