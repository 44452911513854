import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  Login,
  Register,
  Home,
  NoResults,
  Ticketing,
  Checkout,
  SeasonalDestinations,
  Stations,
  Summary,
  MyTrips,
  ForgotPassword,
  ShareableTicket,
  UserProfile,
  About,
  FAQs,
  BusClasses,
  OurServices,
  TrackTrip,
  Payment,
  Dahab,
  Hurgada,
  NorthCoast,
  SharmelEheikh,
  Asyout,
  RasGhareb,
  Luxor,
  Blog,
  CairoLuxor,
  CairoHurghada,
  CairoAsyout,
  HurghadaBestWay,
  SecretKeyBlog
} from 'scenes';
import {
  Layout,
  PrivateRoute,
  ComplaintForm,
  PrivateBusForm,
  ContactUsForm,
  DeleteAcontForm,
} from 'components';
import AuthDataProvider from 'utilities/auth-context';
import CancelTrip from 'components/cancel-trip';
import Notifications from 'scenes/notifications';
import TermsAndConditions from 'scenes/terms';
import firebase from 'firebase/app';
import "firebase/analytics";
import useScript from 'react-script-hook';

const privateRoutes = {
  // TODO: why checkout is not here?
  authenticatedRoutes: [
    '/my-trips',
    '/my-trips/complaint',
    '/my-trips/cancel',
    '/profile',
  ],
  guestRoutes: ['/login', '/register', '/forgot-password'],
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
  env: process.env.REACT_APP_APP_ENV,
};
const App = () => {
  // Initialize Firebase App
  useEffect(() => {
       firebase.initializeApp(firebaseConfig);
       firebase.analytics()

  }, []);

  useScript({
    src: process.env.REACT_APP_FAWRY_PLUGIN,
    // eslint-disable-next-line no-undef
    onload: () => (window.FawryPay = FawryPay),
  });
  /** Initialize routes for the application.
   * To initialize route, specify route with path and component.
   * eg. <Route exact path component />
   */
  return (
    <AuthDataProvider>
      <Layout>
        <Switch>
          <Route exact path="/ticketing" component={Ticketing} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/payment" component={Payment} />
          <Route exact path="/summary" component={Summary} />
          <Route exact path="/no-results" component={NoResults} />
          <Route exact path="/stations" component={Stations} />
          <Route exact path="/destinations" component={SeasonalDestinations} />
          <Route exact path="/destinations/Dahab" component={Dahab} />
          <Route exact path="/destinations/Hurghada" component={Hurgada} />
          <Route exact path="/destinations/Asyout" component={Asyout} />
          <Route exact path="/destinations/ras-ghareb" component={RasGhareb} />
          <Route exact path="/destinations/luxor" component={Luxor} />
          <Route
            exact
            path="/destinations/North-Coast"
            component={NorthCoast}
          />
          <Route
            exact
            path="/destinations/sharm-el-sheikh"
            component={SharmelEheikh}
          />
          <Route exact path="/private-bus" component={PrivateBusForm} />
          <Route exact path="/contact-us" component={ContactUsForm} />
          <Route exact path="/delete-account" component={DeleteAcontForm} />
          <Route exact path="/ticket/:id" component={ShareableTicket} />
          <Route exact path="/about" component={About} />
          <Route exact path="/notifications" component={Notifications} />
          <Route exact path="/faqs" component={FAQs} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/cairo-luxor" component={CairoLuxor} />
          <Route exact path="/cairo-hurghada" component={CairoHurghada} />
          <Route exact path="/cairo-asyout" component={CairoAsyout} />
          <Route exact path="/hurghada-best-way" component={HurghadaBestWay} />
          <Route exact path="/secret-key" component={SecretKeyBlog} />
          <Route exact path="/services" component={OurServices} />
          <Route
            exact
            path="/privacy-and-policy"
            component={TermsAndConditions}
          />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Route exact path="/" component={Home} />
          {/* <Route exact path="/home" component={Home} /> */}
          <Route exact path="/faqs" component={FAQs} />
          <Route exact path="/bus-classes" component={BusClasses} />
          <Route exact path="/track-trip" component={TrackTrip} />
          <PrivateRoute privateRoutes={privateRoutes}>
            <Route exact path="/my-trips" component={MyTrips} />
            <Route exact path="/my-trips/complaint" component={ComplaintForm} />
            <Route exact path="/my-trips/cancel" component={CancelTrip} />
            <Route exact path="/profile" component={UserProfile} />

            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
          </PrivateRoute>
        </Switch>

        {/* {isMobile && (
          <Modal
            footer={[]}
            visible={showAppDiv}
            onCancel={() => setAppDev(false)}
          >
            <div style={appStyle}>
              <p style={{ width: '100%', textAlign: 'center' }}>{t('title')}</p>
              <div>
                <p
                  style={{
                    width: '60%',
                    textAlign: 'center',
                    display: 'inline-block',
                  }}
                >
                  {' '}
                  <img
                    style={{ width: '10%' }}
                    rel="icon"
                    src="https://bluebus.com.eg/favicon.ico"
                  />{' '}
                  {t('app')}
                </p>
                <Button
                  type="primary"
                  style={{ width: '30%', textAlign: 'center' }}
                  onClick={() =>
                    (window.location.href = 'https://onelink.to/j3wt7p')
                  }
                >
                  {t('open')}
                </Button>
                <p
                  style={{
                    width: '60%',
                    textAlign: 'center',
                    display: 'inline-block',
                  }}
                >
                  {t('browser')}
                </p>
                <Button
                  type="primary"
                  ghost
                  style={{ width: '30%', textAlign: 'center' }}
                  onClick={() => setAppDev(false)}
                >
                  {t('continue')}
                </Button>
              </div>
            </div>
          </Modal>
        )} */}
      </Layout>
    </AuthDataProvider>
  );
};

export default App;
